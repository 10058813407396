<template>
  <v-row class="d-flex justify-center align-start">
    <v-col cols="12" no-gutters>
      <v-card
        v-if="isActivateAccount && accountData?.active"
        class="elevation-0 card-border"
      >
        <v-card-text class="text-font">
          <div class="text-h5 text-center">
            <strong>{{
              $t('exception.account.activate.activated.title')
            }}</strong>
          </div>
          <p
            class="pt-4 text-center"
            v-html="
              $t('exception.account.activate.activated.description').replace(
                '{{accountData.email}}',
                accountData.email
              )
            "
          ></p>
          <div class="pt-4">
            <v-card color="#F8F9F8">
              <v-card-text class="text-font">
                <div
                  class="text-justify"
                  v-html="$t('exception.account.activate.support')"
                ></div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-else-if="!isActivateAccount && accountData?.statusCode === 4002"
        class="elevation-0 card-border"
      >
        <v-card-text class="text-font">
          <div class="text-h5 text-center">
            <strong>
              {{ $t('exception.account.activate.active.title') }}
            </strong>
          </div>
          <p
            class="pt-4 text-center"
            v-html="
              $t('exception.account.activate.active.description').replace(
                '{{accountData.context.email}}',
                accountData?.context?.email
              )
            "
          ></p>
          <v-card-actions class="d-flex justify-center pt-4">
            <v-btn @click="onLogin" color="success">{{
              $t('login.platform_access')
            }}</v-btn>
          </v-card-actions>
          <div class="pt-4">
            <v-card color="#F8F9F8" class="elevation-0">
              <v-card-text class="text-font">
                <div
                  class="text-justify"
                  v-html="$t('exception.account.activate.support')"
                ></div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-else-if="!isActivateAccount && accountData?.statusCode === 4003"
        class="elevation-0 card-border"
      >
        <v-card-text class="text-font">
          <div class="text-h5 text-center">
            <strong>
              {{ $t('exception.account.activate.code_invalid.title') }}
            </strong>
          </div>
          <p
            class="pt-4 text-center"
            v-html="$t('exception.account.activate.code_invalid.description')"
          ></p>
          <v-card-actions class="d-flex justify-center pt-4">
            <v-btn @click="onResendActivationEmail" color="success">
              {{ $t('exception.account.activate.resend_email') }}
            </v-btn>
          </v-card-actions>
          <div class="pt-4">
            <v-card color="#F8F9F8" class="elevation-0">
              <v-card-text class="text-font">
                <div
                  class="text-justify"
                  v-html="$t('exception.account.activate.support')"
                ></div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { router } from '@/router'
export default {
  name: 'AlertAccountActivate',

  props: {
    isActivateAccount: {
      required: true,
      type: Boolean,
    },
    accountData: {
      required: true,
      type: Object,
    },
  },
  created() {
    setTimeout(() => {
      this.onLogin()
    }, 30000)
  },
  methods: {
    onResendActivationEmail() {
      this.$emit('onResendEmail')
    },
    onLogin() {
      router.push({ path: '/login' })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .text-h5 {
  font-family: 'Rubik' !important;
}
.text-font {
  font-family: 'Rubik' !important;
}

.card-border {
  border-color: #e6e9e6;
  border-style: solid;
}
</style>
