<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      lg="4"
      md="5"
      sm="12"
      class="px-6 px-md-12 py-4 d-flex flex-column"
    >
      <v-row class="header-login d-flex justify-space-between">
        <v-col class="mx-0" md="5">
          <img
            alt="digifarmz-icon"
            src="img/logos/logo-digifarmz.svg"
            width="160px"
          />
        </v-col>
        <v-col md="6" class="header-login_language-selector mx-0">
          <language-selector />
        </v-col>
      </v-row>
      <alert-account-activate
        :is-activate-account="isActivateAccount"
        :account-data="accountData"
        @onResendEmail="resendActivationEmail"
      />
      <footer-support />
    </v-col>
    <v-col
      v-if="!isMobile"
      cols="12"
      lg="8"
      md="7"
      sm="0"
      class="farmer-img__wrapper"
    >
      <img
        class="login-side-image"
        alt="farmer"
        src="img/backgrounds/img-farmer-login-1920.webp"
        srcset="
          img/backgrounds/img-farmer-login-1264.webp 1264w,
          img/backgrounds/img-farmer-login-1920.webp 1920w
        "
        sizes="
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import FooterSupport from '@/modules/auth/components/FooterSupport.vue'
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import AlertAccountActivate from '@/modules/auth/components/AlertAccountActivate.vue'

export default {
  name: 'ConfirmAccount',

  mixins: [LayoutMixin],

  components: {
    FooterSupport,
    LanguageSelector,
    AlertAccountActivate,
  },

  data() {
    return {
      isActivateAccount: false,
      accountData: {},
    }
  },

  created() {
    this.activeAccount().then((response) => {
      const { data } = response

      if (Object.hasOwn(data, 'key')) {
        this.isActivateAccount = false
      } else {
        this.isActivateAccount = true
      }
      this.accountData = data
    })
  },
  methods: {
    async resendActivationEmail() {
      const { email } = this.$route.query
      const lang = this.$root.$i18n.locale || 'en'
      if (email) {
        return await this.$api.accountPending.resend(email, lang)
      }
    },
    async activeAccount() {
      const { email, code } = this.$route.query
      if (email && code) {
        return await this.$api.accountPending.active(email, code)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-text-field--outlined fieldset {
  top: 0 !important;
}
::v-deep .v-input__control {
  height: 0px;
}

::v-deep hr {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0.4;
}

.header-login_language-selector {
  height: 74px;
  max-height: 74px;
}

.farmer-img__wrapper {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  margin: 12px 0px !important;

  .login-side-image {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
}
</style>
