var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"d-flex justify-center align-start"},[_c('v-col',{attrs:{"cols":"12","no-gutters":""}},[(_vm.isActivateAccount && _vm.accountData?.active)?_c('v-card',{staticClass:"elevation-0 card-border"},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-h5 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t('exception.account.activate.activated.title')))])]),_c('p',{staticClass:"pt-4 text-center",domProps:{"innerHTML":_vm._s(
            _vm.$t('exception.account.activate.activated.description').replace(
              '{{accountData.email}}',
              _vm.accountData.email
            )
          )}}),_c('div',{staticClass:"pt-4"},[_c('v-card',{attrs:{"color":"#F8F9F8"}},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.$t('exception.account.activate.support'))}})])],1)],1)])],1):(!_vm.isActivateAccount && _vm.accountData?.statusCode === 4002)?_c('v-card',{staticClass:"elevation-0 card-border"},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-h5 text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('exception.account.activate.active.title'))+" ")])]),_c('p',{staticClass:"pt-4 text-center",domProps:{"innerHTML":_vm._s(
            _vm.$t('exception.account.activate.active.description').replace(
              '{{accountData.context.email}}',
              _vm.accountData?.context?.email
            )
          )}}),_c('v-card-actions',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onLogin}},[_vm._v(_vm._s(_vm.$t('login.platform_access')))])],1),_c('div',{staticClass:"pt-4"},[_c('v-card',{staticClass:"elevation-0",attrs:{"color":"#F8F9F8"}},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.$t('exception.account.activate.support'))}})])],1)],1)],1)],1):(!_vm.isActivateAccount && _vm.accountData?.statusCode === 4003)?_c('v-card',{staticClass:"elevation-0 card-border"},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-h5 text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('exception.account.activate.code_invalid.title'))+" ")])]),_c('p',{staticClass:"pt-4 text-center",domProps:{"innerHTML":_vm._s(_vm.$t('exception.account.activate.code_invalid.description'))}}),_c('v-card-actions',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onResendActivationEmail}},[_vm._v(" "+_vm._s(_vm.$t('exception.account.activate.resend_email'))+" ")])],1),_c('div',{staticClass:"pt-4"},[_c('v-card',{staticClass:"elevation-0",attrs:{"color":"#F8F9F8"}},[_c('v-card-text',{staticClass:"text-font"},[_c('div',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.$t('exception.account.activate.support'))}})])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }